import React from "react";
import SVG_LINK_PIC from "../../../../../assets/images/vector/pages/de/main/Guides/arrow-right-link.svg";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const Link = ({ mobile }) => (
  <a
    className={getTrueClasses(
      "PgDMain-G__Link",
      mobile && "PgDMain-G__Link_mob"
    )}
    href="/de/blog"
    target="true"
  >
    Mehr Artikel in unserem Blog <img src={SVG_LINK_PIC} alt="->" />
  </a>
);

export default Link;

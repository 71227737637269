import React from "react";
import IdeaScreenshot from "./IdeaScreenshot";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const Idea = () => {
  return (
    <section className="PgDMain-I">
      <div className="container">
        <h2 className="PgDMain-I__title">
          Möchten Sie boomende und einsatzbereite Produktideen erhalten?
        </h2>
        <div className="PgDMain-I__content">
          <IdeaScreenshot />
          <div className="PgDMain-I__info">
            <p className="PgDMain-I__text PgDMain-I__text_bold">
              Die Analysten von Sellerhook, Teil der AMZScout-Familie, helfen
              Ihnen, potenzielle Bestseller für den Verkauf auf Amazon zu
              finden.
            </p>
            <p className="PgDMain-I__text PgDMain-I__text_bold">
              Sie können Produkte vermeiden, deren Verkäufe rückläufig sind, und
              bis zu 90 Prozent der Verkäufe in neuen Nischen erhalten und so
              Ihre Konkurrenten vernichten.
            </p>
            <p className="PgDMain-I__text">
              Bestellen Sie eine Recherche zu aufstrebenden Produkten für Ihr
              genaues Geschäftsmodell und Produktsortiment mit einem einmaligen
              Kauf. Alle Produkte werden aufgrund von Parametern wie
              Gewinnspanne, Umsatz, Wachstumstempo, geringem Wettbewerb oder
              anderen Kriterien, die Ihnen wichtig sind, übertreffen.
            </p>
            <TrackedLink
              class="PgDMain-I__link"
              category="main_page"
              action="bestellen sie ihre produktstudie"
              path="https://sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=main_text"
            >
              BESTELLEN SIE IHRE PRODUKTSTUDIE
            </TrackedLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Idea;

import React from "react";
import "./index.scss";

const ProValueList = ({ list }) => {
  return (
    <ol className="PgDMain-PV-List">
      {list.map((item, index) => (
        <li className="PgDMain-PV-List-item" key={item}>
          <span className="PgDMain-PV-List__num">{index + 1}</span>
          {item}
        </li>
      ))}
    </ol>
  );
};

export default ProValueList;

import React from "react";
import "./index.scss";

const Title = () => (
  <h2 className="PgDMain-HI__Title">
    Komplettes Toolset für den Start und das Wachstum Ihres Amazon-Geschäfts
  </h2>
);

export default Title;

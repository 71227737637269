import React from "react";
import "./index.scss";

const ProValueImage = ({ customClasses, pictures }) => {
  const {
    PNG,
    PNG_2x,
    PNG_1200,
    PNG_1200_2x,
    PNG_992,
    PNG_992_2x,
    PNG_768,
    PNG_768_2x,
    PNG_Mob,
    PNG_Mob_2x,
    WEBP,
    WEBP_2x,
    WEBP_1200,
    WEBP_1200_2x,
    WEBP_992,
    WEBP_992_2x,
    WEBP_768,
    WEBP_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
  } = pictures;
  return (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1366px)"
        srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
      />
      <source media="(min-width: 1366px)" srcSet={`${PNG} 1x, ${PNG_2x} 2x`} />
      <source
        type="image/webp"
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${WEBP_1200} 1x, ${WEBP_1200_2x} 2x`}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${PNG_1200} 1x, ${PNG_1200_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${WEBP_992} 1x, ${WEBP_992_2x} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${PNG_992} 1x, ${PNG_992_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${WEBP_768} 1x, ${WEBP_768_2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${PNG_768} 1x, ${PNG_768_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${WEBP_Mob} 1x, ${WEBP_Mob_2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${PNG_Mob} 1x, ${PNG_Mob_2x} 2x`}
      />
      <img className={customClasses} src={PNG} alt="" loading="lazy" />
    </picture>
  );
};

export default ProValueImage;

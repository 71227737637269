import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import useCheckLicense from "../../../../hooks/useCheckLicense";
import { PAGES } from "../../../../helpers/constants";
import { getTrueClasses } from "../../../../helpers";
import Title from "./Title";
import Points from "./Points";
import LicenseEmailCollectContainer from "../../../../containers/common/forms/LicenseEmailCollectContainer";
import Offer from "./Offer";
import Screen from "./Screen";
import "./index.scss";

const HomeIntro = ({ homeIntroRef }) => {
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);

  const isPaid = useCheckLicense();
  return (
    <section ref={homeIntroRef} className="PgDMain-HI" id="home">
      <div className="container">
        <div className="PgDMain-HI-row">
          <div className="PgDMain-HI-info">
            <Title />
            <Points />
            <LicenseEmailCollectContainer
              page={PAGES.MAIN_PAGE}
              buttonTextFirstState="Probieren Sie AMZScout kostenlos aus!"
              buttonTextSecondState={
                isPaid
                  ? "INS IHR KONTO EINLOGGEN"
                  : "PROBIEREN SIE AMZSCOUT KOSTENLOS AUS!"
              }
              customClass={getTrueClasses(
                "PgDMain-HI-emailCollect",
                // isOpacity && "emailCollect__opacity"
              )}
              language="de"
              customPlaceholder={getTrueClasses(
                isNotInitMobile && "example@amzscout.net"
              )}
            />
            <Offer isPaid={isPaid} />
          </div>
          <Screen />
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;

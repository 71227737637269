import React from "react";
import SUCCESS_STORIES_LIST from "./index.content";
import "./index.scss";
import Item from "./Item";

const SuccessStories = () => {
  return (
    <section className="PgDMain-SS">
      <div className="container">
        <div className="PgDMain-SS-content">
          <div className="PgDMain-SS-header">
            <h2 className="PgDMain-SS-header__title">Erfolgsgeschichten</h2>
            <div className="PgDMain-SS-header__subtitle">
              AMZScout Kundenbewertungen sehen:
            </div>
          </div>
          <div className="PgDMain-SS-list">
            {SUCCESS_STORIES_LIST.map((data) => (
              <Item key={data.name} {...data} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStories;

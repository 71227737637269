import PNG_One from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1.png";
import PNG_One_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1@2x.png";
import PNG_One_Mob from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1@mob.png";
import PNG_One_Mob_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1@mob2x.png";
import WEBP_One from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1.webp";
import WEBP_One_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1@2x.webp";
import WEBP_One_Mob from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1@mob.webp";
import WEBP_One_Mob_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_1@mob2x.webp";

import PNG_Two from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2.png";
import PNG_Two_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2@2x.png";
import PNG_Two_Mob from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2@mob.png";
import PNG_Two_Mob_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2@mob2x.png";
import WEBP_Two from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2.webp";
import WEBP_Two_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2@2x.webp";
import WEBP_Two_Mob from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2@mob.webp";
import WEBP_Two_Mob_2x from "../../../../../assets/images/raster/components/Idea/PgEMain-Idea_2@mob2x.webp";

const IDEA_SCREENSHOT_CONTENT =  {
  firstScreenshot: {
    JPEG: PNG_One,
    JPEG2x: PNG_One_2x,
    JPEGMob: PNG_One_Mob,
    JPEGMob2x: PNG_One_Mob_2x,
    WEBP: WEBP_One,
    WEBP2x: WEBP_One_2x,
    WEBPMob: WEBP_One_Mob,
    WEBPMob2x: WEBP_One_Mob_2x,
  },
  secondScreenshot: {
    JPEG: PNG_Two,
    JPEG2x: PNG_Two_2x,
    JPEGMob: PNG_Two_Mob,
    JPEGMob2x: PNG_Two_Mob_2x,
    WEBP: WEBP_Two,
    WEBP2x: WEBP_Two_2x,
    WEBPMob: WEBP_Two_Mob,
    WEBPMob2x: WEBP_Two_Mob_2x,
  }
}

export default IDEA_SCREENSHOT_CONTENT;

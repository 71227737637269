import React from "react";
import PRO_VALUE_CONTENT from "./index.content";
import ProValueImage from "./ProValueImage";
import ProValueList from "./ProValueList";
import "./index.scss";

const ProValue = () => {
  return (
    <section className="PgDMain-PV PgDMain-PV_home">
      <div className="PgDMain-PV__body">
        <div className="container">
          <h2 className="PgDMain-PV__title">
            Beste Amazon-Produktrecherche-Tool
          </h2>
          <p className="PgDMain-PV__subTitle">
            Holen Sie sich alles, was Sie für den Verkauf auf Amazon brauchen
          </p>
          {PRO_VALUE_CONTENT.map(({ pictures, title, list, text }, i) => (
            <div
              className={`PgDMain-PV__row PgDMain-PV__row${i + 1}`}
              key={title}
            >
              <div className="PgDMain-PV__info">
                <div className="PgDMain-PV__row-title">{title}</div>
                {text ? (
                  <div className="PgDMain-PV__text">{text}</div>
                ) : (
                  <ProValueList list={list} />
                )}
              </div>
              <ProValueImage
                pictures={pictures}
                customClasses={`PgDMain-PV__Image PgDMain-PV__Image_mod${
                  i + 1
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProValue;

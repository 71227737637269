import PNG_One from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1.png";
import PNG_One_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@2x.png";
import PNG_One_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@1200.png";
import PNG_One_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@1200_2x.png";
import PNG_One_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@992.png";
import PNG_One_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@992_2x.png";
import PNG_One_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@768.png";
import PNG_One_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@768_2x.png";
import PNG_One_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@mob.png";
import PNG_One_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@mob_2x.png";

import WEBP_One from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1.webp";
import WEBP_One_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@2x.webp";
import WEBP_One_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@1200.webp";
import WEBP_One_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@1200_2x.webp";
import WEBP_One_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@992.webp";
import WEBP_One_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@992_2x.webp";
import WEBP_One_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@768.webp";
import WEBP_One_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@768_2x.webp";
import WEBP_One_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@mob.webp";
import WEBP_One_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_1@mob_2x.webp";

import PNG_Two from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2.png";
import PNG_Two_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@2x.png";
import PNG_Two_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@1200.png";
import PNG_Two_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@1200_2x.png";
import PNG_Two_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@992.png";
import PNG_Two_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@992_2x.png";
import PNG_Two_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@768.png";
import PNG_Two_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@768_2x.png";
import PNG_Two_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@mob.png";
import PNG_Two_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@mob_2x.png";

import WEBP_Two from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2.webp";
import WEBP_Two_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@2x.webp";
import WEBP_Two_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@1200.webp";
import WEBP_Two_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@1200_2x.webp";
import WEBP_Two_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@992.webp";
import WEBP_Two_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@992_2x.webp";
import WEBP_Two_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@768.webp";
import WEBP_Two_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@768_2x.webp";
import WEBP_Two_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@mob.webp";
import WEBP_Two_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_2@mob_2x.webp";

import PNG_Three from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3.png";
import PNG_Three_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@2x.png";
import PNG_Three_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@1200.png";
import PNG_Three_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@1200_2x.png";
import PNG_Three_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@992.png";
import PNG_Three_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@992_2x.png";
import PNG_Three_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@768.png";
import PNG_Three_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@768_2x.png";
import PNG_Three_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@mob.png";
import PNG_Three_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@mob_2x.png";

import WEBP_Three from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3.webp";
import WEBP_Three_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@2x.webp";
import WEBP_Three_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@1200.webp";
import WEBP_Three_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@1200_2x.webp";
import WEBP_Three_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@992.webp";
import WEBP_Three_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@992_2x.webp";
import WEBP_Three_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@768.webp";
import WEBP_Three_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@768_2x.webp";
import WEBP_Three_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@mob.webp";
import WEBP_Three_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_3@mob_2x.webp";

import PNG_Four from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4.png";
import PNG_Four_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@2x.png";
import PNG_Four_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@1200.png";
import PNG_Four_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@1200_2x.png";
import PNG_Four_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@992.png";
import PNG_Four_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@992_2x.png";
import PNG_Four_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@768.png";
import PNG_Four_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@768_2x.png";
import PNG_Four_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@mob.png";
import PNG_Four_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@mob_2x.png";

import WEBP_Four from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4.webp";
import WEBP_Four_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@2x.webp";
import WEBP_Four_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@1200.webp";
import WEBP_Four_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@1200_2x.webp";
import WEBP_Four_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@992.webp";
import WEBP_Four_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@992_2x.webp";
import WEBP_Four_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@768.webp";
import WEBP_Four_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@768_2x.webp";
import WEBP_Four_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@mob.webp";
import WEBP_Four_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_4@mob_2x.webp";

import PNG_Five from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5.png";
import PNG_Five_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@2x.png";
import PNG_Five_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@1200.png";
import PNG_Five_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@1200_2x.png";
import PNG_Five_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@992.png";
import PNG_Five_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@992_2x.png";
import PNG_Five_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@768.png";
import PNG_Five_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@768_2x.png";
import PNG_Five_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@mob.png";
import PNG_Five_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@mob_2x.png";

import WEBP_Five from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5.webp";
import WEBP_Five_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@2x.webp";
import WEBP_Five_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@1200.webp";
import WEBP_Five_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@1200_2x.webp";
import WEBP_Five_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@992.webp";
import WEBP_Five_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@992_2x.webp";
import WEBP_Five_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@768.webp";
import WEBP_Five_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@768_2x.webp";
import WEBP_Five_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@mob.webp";
import WEBP_Five_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_5@mob_2x.webp";

import PNG_Six from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6.png";
import PNG_Six_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@2x.png";
import PNG_Six_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@1200.png";
import PNG_Six_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@1200_2x.png";
import PNG_Six_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@992.png";
import PNG_Six_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@992_2x.png";
import PNG_Six_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@768.png";
import PNG_Six_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@768_2x.png";
import PNG_Six_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@mob.png";
import PNG_Six_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@mob_2x.png";

import WEBP_Six from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6.webp";
import WEBP_Six_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@2x.webp";
import WEBP_Six_1200 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@1200.webp";
import WEBP_Six_1200_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@1200_2x.webp";
import WEBP_Six_992 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@992.webp";
import WEBP_Six_992_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@992_2x.webp";
import WEBP_Six_768 from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@768.webp";
import WEBP_Six_768_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@768_2x.webp";
import WEBP_Six_Mob from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@mob.webp";
import WEBP_Six_Mob_2x from "../../../../assets/images/raster/pages/de/main/ProValue/ProValue_6@mob_2x.webp";

const PRO_VALUE_CONTENT = [
  {
    pictures: {
      PNG: PNG_One,
      PNG_2x: PNG_One_2x,
      PNG_1200: PNG_One_1200,
      PNG_1200_2x: PNG_One_1200_2x,
      PNG_992: PNG_One_992,
      PNG_992_2x: PNG_One_992_2x,
      PNG_768: PNG_One_768,
      PNG_768_2x: PNG_One_768_2x,
      PNG_Mob: PNG_One_Mob,
      PNG_Mob_2x: PNG_One_Mob_2x,
      WEBP: WEBP_One,
      WEBP_2x: WEBP_One_2x,
      WEBP_1200: WEBP_One_1200,
      WEBP_1200_2x: WEBP_One_1200_2x,
      WEBP_992: WEBP_One_992,
      WEBP_992_2x: WEBP_One_992_2x,
      WEBP_768: WEBP_One_768,
      WEBP_768_2x: WEBP_One_768_2x,
      WEBP_Mob: WEBP_One_Mob,
      WEBP_Mob_2x: WEBP_One_Mob_2x
    },
    title: "Gewinnbringende Produkte für den Verkauf auf Amazon finden",
    list: [
      "Heiße neue Produktideen mit Hilfe unserer Produktdatenbank entwickeln, die 550 Millionen Produkten umfasst",
      "Identifizieren und vergleichen Sie Produkte mit dem höchsten Gewinnpotenzial und bewerten Sie die Konkurrenz mit der berühmten PRO-Erweiterung",
      "Lassen Sie sich profitable Produkte, die zu den TOP 1% gehören, direkt per E-Mail zusenden",
      "Erhalten Sie Zugang zur genauesten Analyselösung der Branche"
    ]
  },
  {
    pictures: {
      PNG: PNG_Two,
      PNG_2x: PNG_Two_2x,
      PNG_1200: PNG_Two_1200,
      PNG_1200_2x: PNG_Two_1200_2x,
      PNG_992: PNG_Two_992,
      PNG_992_2x: PNG_Two_992_2x,
      PNG_768: PNG_Two_768,
      PNG_768_2x: PNG_Two_768_2x,
      PNG_Mob: PNG_Two_Mob,
      PNG_Mob_2x: PNG_Two_Mob_2x,
      WEBP: WEBP_Two,
      WEBP_2x: WEBP_Two_2x,
      WEBP_1200: WEBP_Two_1200,
      WEBP_1200_2x: WEBP_Two_1200_2x,
      WEBP_992: WEBP_Two_992,
      WEBP_992_2x: WEBP_Two_992_2x,
      WEBP_768: WEBP_Two_768,
      WEBP_768_2x: WEBP_Two_768_2x,
      WEBP_Mob: WEBP_Two_Mob,
      WEBP_Mob_2x: WEBP_Two_Mob_2x
    },
    title: "Lernen Sie, wie man auf dem Amazon Marktplatz verkauft",
    text: "Erfahren Sie genau, wie Sie profitable Produkte auswählen, Artikel von Lieferanten beziehen und Ihr Geschäft vermarkten - mit Schritt-für-Schritt-Video-Kursen und Masterclasses, die von 7-stelligen Amazon-Händlern erstellt wurden."
  },
  {
    pictures: {
      PNG: PNG_Three,
      PNG_2x: PNG_Three_2x,
      PNG_1200: PNG_Three_1200,
      PNG_1200_2x: PNG_Three_1200_2x,
      PNG_992: PNG_Three_992,
      PNG_992_2x: PNG_Three_992_2x,
      PNG_768: PNG_Three_768,
      PNG_768_2x: PNG_Three_768_2x,
      PNG_Mob: PNG_Three_Mob,
      PNG_Mob_2x: PNG_Three_Mob_2x,
      WEBP: WEBP_Three,
      WEBP_2x: WEBP_Three_2x,
      WEBP_1200: WEBP_Three_1200,
      WEBP_1200_2x: WEBP_Three_1200_2x,
      WEBP_992: WEBP_Three_992,
      WEBP_992_2x: WEBP_Three_992_2x,
      WEBP_768: WEBP_Three_768,
      WEBP_768_2x: WEBP_Three_768_2x,
      WEBP_Mob: WEBP_Three_Mob,
      WEBP_Mob_2x: WEBP_Three_Mob_2x
    },
    title: "Erzielen Sie hohe Gewinne durch den Verkauf von nachweislich profitablen Produkten",
    list: [
      "Finden Sie leicht Produkte, die für Dropshipping, Arbitrage und Großhandel geeignet sind",
      "Berechnen Sie den Preis, der benötigt wird, um die Buy Box zu besitzen und wie viel Umsatz Sie machen können",
      "Finden Sie heraus, welche Produkte zusätzliche Investitionen und spezielle Genehmigungen benötigen, um sie mit Leichtigkeit zu verkaufen"
    ]
  },
  {
    pictures: {
      PNG: PNG_Four,
      PNG_2x: PNG_Four_2x,
      PNG_1200: PNG_Four_1200,
      PNG_1200_2x: PNG_Four_1200_2x,
      PNG_992: PNG_Four_992,
      PNG_992_2x: PNG_Four_992_2x,
      PNG_768: PNG_Four_768,
      PNG_768_2x: PNG_Four_768_2x,
      PNG_Mob: PNG_Four_Mob,
      PNG_Mob_2x: PNG_Four_Mob_2x,
      WEBP: WEBP_Four,
      WEBP_2x: WEBP_Four_2x,
      WEBP_1200: WEBP_Four_1200,
      WEBP_1200_2x: WEBP_Four_1200_2x,
      WEBP_992: WEBP_Four_992,
      WEBP_992_2x: WEBP_Four_992_2x,
      WEBP_768: WEBP_Four_768,
      WEBP_768_2x: WEBP_Four_768_2x,
      WEBP_Mob: WEBP_Four_Mob,
      WEBP_Mob_2x: WEBP_Four_Mob_2x
    },
    title: "Suche nach lokalen und internationalen Lieferanten auf Alibaba",
    list: [
      "Entdecken Sie mit nur einem Klick erschwingliche und vertrauenswürdige Lieferanten aus allen Ländern, einschließlich den USA und China",
      "Erhalten Sie Zugang zu Millionen von Herstellern, die bereit sind, Ihre Eigenmarkenprodukte herzustellen",
      "Finden Sie die Produkte Ihrer Konkurrenten auf Alibaba.com und fügen Sie sie Ihrem Bestand hinzu, um Ihr Geschäft zu vergrößern",
      "Vergleichen Sie Lieferanten nach Bewertung und Geschäftstätigkeit, um die zuverlässigsten Partner für Ihr Unternehmen auszuwählen"
    ]
  },
  {
    pictures: {
      PNG: PNG_Five,
      PNG_2x: PNG_Five_2x,
      PNG_1200: PNG_Five_1200,
      PNG_1200_2x: PNG_Five_1200_2x,
      PNG_992: PNG_Five_992,
      PNG_992_2x: PNG_Five_992_2x,
      PNG_768: PNG_Five_768,
      PNG_768_2x: PNG_Five_768_2x,
      PNG_Mob: PNG_Five_Mob,
      PNG_Mob_2x: PNG_Five_Mob_2x,
      WEBP: WEBP_Five,
      WEBP_2x: WEBP_Five_2x,
      WEBP_1200: WEBP_Five_1200,
      WEBP_1200_2x: WEBP_Five_1200_2x,
      WEBP_992: WEBP_Five_992,
      WEBP_992_2x: WEBP_Five_992_2x,
      WEBP_768: WEBP_Five_768,
      WEBP_768_2x: WEBP_Five_768_2x,
      WEBP_Mob: WEBP_Five_Mob,
      WEBP_Mob_2x: WEBP_Five_Mob_2x
    },
    title: "Schnell ein erfolgreiches Produkt auf den Markt bringen",
    list: [
      "Mit den AMZScout Keyword-Tools erhalten Sie alle Keywords, die Sie benötigen, um effektive Produktangebote und PPC-Kampagnen zu erstellen",
      "Erhalten Sie Empfehlungen und Berichte, die Ihnen helfen, Ihre Angebote zu optimieren und Ihre Konkurrenz zu schlagen",
      "Erstellen Sie die perfekten Angebote und PPC-Kampagnen, die es Ihnen ermöglichen, Amazons Suchalgorithmus zu knacken und Ihren Umsatz zu steigern",
      "Finden Sie schnell Lieferanten für jedes neue Produkt, indem Sie Alibaba-Vorschläge nutzen"
    ]
  },
  {
    pictures: {
      PNG: PNG_Six,
      PNG_2x: PNG_Six_2x,
      PNG_1200: PNG_Six_1200,
      PNG_1200_2x: PNG_Six_1200_2x,
      PNG_992: PNG_Six_992,
      PNG_992_2x: PNG_Six_992_2x,
      PNG_768: PNG_Six_768,
      PNG_768_2x: PNG_Six_768_2x,
      PNG_Mob: PNG_Six_Mob,
      PNG_Mob_2x: PNG_Six_Mob_2x,
      WEBP: WEBP_Six,
      WEBP_2x: WEBP_Six_2x,
      WEBP_1200: WEBP_Six_1200,
      WEBP_1200_2x: WEBP_Six_1200_2x,
      WEBP_992: WEBP_Six_992,
      WEBP_992_2x: WEBP_Six_992_2x,
      WEBP_768: WEBP_Six_768,
      WEBP_768_2x: WEBP_Six_768_2x,
      WEBP_Mob: WEBP_Six_Mob,
      WEBP_Mob_2x: WEBP_Six_Mob_2x
    },
    title: "Analysieren Sie die Daten der Konkurrenten",
    text: "Überwachen Sie deren Verkäufe, Preise, Schlüsselwörter und Lagerbestände, um sich einen Vorteil zu verschaffen."
  }
];
export default PRO_VALUE_CONTENT;

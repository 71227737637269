import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getTrueClasses } from "../../../../../helpers";
import FastImage from "../../../../../containers/common/FastImage";
import Delta from "../../../../main/HomeIntro/Screen/Delta";
import "./index.scss";

const Screen = () => {
  const [isShowVideo, setIsShowVideo] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { regex: "/de-home-video/" }, ext: { eq: ".jpg" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);
  return (
    <div
      onClick={() => setIsShowVideo(true)}
      className={getTrueClasses(
        "PgDMain-HI-S",
        !isShowVideo && "PgDMain-HI-S_screenPlay"
      )}
    >
      {isShowVideo ? (
        <iframe
          src="https://www.youtube.com/embed/UCUEItN0ONc?autoplay=1&modestbranding=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          title="youtube"
        />
      ) : (
        <>
          <FastImage
            imageData={data}
            imageClassName="PgDMain-HI-S__img"
            imageAltName="home image"
            isAdaptive
            // isWithRatio
            defaultName="de-home-video"
          />
          <div className="PgDMain-HI-S-play">
            <Delta />
          </div>
        </>
      )}
    </div>
  );
};

export default Screen;

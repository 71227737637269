import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Item = ({ name, text, webp, png }) => (
  <div className="PgDMain-SS-I">
    <div className="PgDMain-SS-I__name">{name}</div>
    <div className="PgDMain-SS-I__text">{text}</div>
    <picture>
      <source type="image/webp" srcSet={webp} />
      <img className="PgDMain-SS-I__img" src={png} alt="" loading="lazy" />
    </picture>
  </div>
);
Item.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  webp: PropTypes.string,
  png: PropTypes.string,
};
export default Item;

const OFFER_CONTENT = {
  unauthorized: {
    status: "unauthorized",
    title: "REGISTRIEREN SIE SICH KOSTENLOS UND ERHALTEN SIE:",
    list: [
      "Testversion des Universal Sales Toolkit",
      "Hot Products und Amazon-Trend-Berichte",
      `Schritt-für-Schritt-Kurs „Wie verkauft man auf Amazon“`,
    ],
  },
  trial: {
    status: "trial",
    title: "JETZT KOSTENLOS ERHALTEN:",
    list: [
      "Testversion des Universal Sales Toolkit",
      "Hot Products und Amazon-Trend-Berichte",
      `Schritt-für-Schritt-Kurs „Wie verkauft man auf Amazon“`,
    ],
  },
  paid: {
    status: "paid",
    title: "IHR VOLLER ZUGANG ZU:",
    list: [
      "All-in-One Sellers-Tool-Set",
      "Hot Products und Amazon-Trend-Berichte",
      `Schritt-für-Schritt-Kurs „Wie verkauft man auf Amazon“`,
    ],
  },
}

export default OFFER_CONTENT

import PNG_JOHN from "../../../../assets/images/raster/components/SuccessStories/john.png"
import WEBP_JOHN from "../../../../assets/images/raster/components/SuccessStories/john.webp"
import PNG_MARIO from "../../../../assets/images/raster/components/SuccessStories/mario.png"
import WEBP_MARIO from "../../../../assets/images/raster/components/SuccessStories/mario.webp"
import PNG_SILVIA from "../../../../assets/images/raster/components/SuccessStories/silvia.png"
import WEBP_SILVIA from "../../../../assets/images/raster/components/SuccessStories/silvia.webp"
import PNG_EMILY from "../../../../assets/images/raster/components/SuccessStories/emily.png"
import WEBP_EMILY from "../../../../assets/images/raster/components/SuccessStories/emily.webp"

const SUCCESS_STORIES_LIST = [
  {
    png: PNG_JOHN,
    webp: WEBP_JOHN,
    name: "John",
    text: "Ich habe andere Tools verwendet, bevor ich zu AMZScout gewechselt habe. Ich mag die Verkäufersoftware sehr, vor allem die Keyword-Finder und Produkt-Tracker, die mir gute Referenzen liefern.",
  },
  {
    png: PNG_MARIO,
    webp: WEBP_MARIO,
    name: "Mario",
    text: "Ehrlich gesagt ist es so, als ob man versucht, den Ausgang im Dunkeln zu finden, und dann plötzlich eine Taschenlampe bekommt. Bei AMZScout gehen Qualität und Produktivität Hand in Hand. Es ist günstig und die beste Vorgehensweise, um nach Produkten zu suchen und die Konkurrenz zu verfolgen.",
  },
  {
    png: PNG_SILVIA,
    webp: WEBP_SILVIA,
    name: "Silvia",
    text: "Ich benutze AMZScout jetzt seit ein paar Monaten und bin wirklich beeindruckt. Es enthält mehrere Tools, um Produktrecherchen durchzuführen, Schlüsselwörter zu finden und vieles mehr. Ich empfehle AMZScout auf jeden Fall für jeden, der ein Amazon-Geschäft aufbaut.",
  },
  {
    png: PNG_EMILY,
    webp: WEBP_EMILY,
    name: "Emily",
    text: "Amazon Scout hat mir geholfen, profitable Produkte zu finden und meine Angebote und PPC-Kampagnen zu optimieren. Seit ich diese Amazon-FBA-Software benutze, habe ich eine enorme Verbesserung festgestellt. Wenn Sie mit dem Verkauf auf Amazon beginnen, sollten Sie es unbedingt ausprobieren.",
  },
]
export default SUCCESS_STORIES_LIST;

import React from "react";
import POINTS_CONTENT from "./index.content";
import "./index.scss";

const Points = () => {
  const { PointIcon, listTexts } = POINTS_CONTENT;
  return (
    <div className="PgDMain-HI-P">
      {listTexts.map((text, i) => (
        <div key={i} className="PgDMain-HI-P__item">
          <img src={PointIcon} alt="-" />
          <div>{text}</div>
        </div>
      ))}
    </div>
  );
};
export default Points;

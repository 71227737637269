import React from "react";
import PropTypes from "prop-types";

const SimpleScreenshot = ({ classes, screenshot, from = 768, to = 767 }) => {
  const { WEBP, WEBP2x, JPEG, JPEG2x, WEBPMob, WEBPMob2x, JPEGMob, JPEGMob2x } =
    screenshot;

  return (
    <picture>
      <source
        type="image/webp"
        media={`(min-width: ${from}px)`}
        srcSet={`${WEBP} 1x, ${WEBP2x} 2x`}
      />
      <source
        media={`(min-width: ${from}px)`}
        srcSet={`${JPEG} 1x, ${JPEG2x} 2x`}
      />
      <source
        type="image/webp"
        media={`(max-width: ${to}px)`}
        srcSet={`${WEBPMob} 1x, ${WEBPMob2x} 2x`}
      />
      <source
        media={`(max-width: ${to}px)`}
        srcSet={`${JPEGMob} 1x, ${JPEGMob2x} 2x`}
      />
      <img src={JPEG} className={classes ?? ""} alt="img" loading="lazy" />
    </picture>
  );
};
SimpleScreenshot.propTypes = {
  screenshot: PropTypes.object.isRequired,
  classes: PropTypes.string,
  from: PropTypes.number,
  to: PropTypes.number,
};

export default SimpleScreenshot;

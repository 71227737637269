import { useContext, useEffect, useState } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import { log } from "../helpers";

const useCheckLicense = () => {
  const [isPaid, setIsPaid] = useState(false);
  const { auth: { h } } = useContext(MediaAuthContext);

  const checkLicense = async () => {
    if (typeof window !== "undefined") {
      try {
        const res = await fetch(
          `${process.env.AUTH_HOST}/v1/licences/software?state=ACTIVE`,
          {
            headers: {
              Accept: "application/json",
              method: "GET",
            },
          }
        );
        if (res.status === 200) {
          const json = await res.json();
          const licenses = json.filter((l) => l.software === "WEB" && !l.trial);
          setIsPaid(licenses.length > 0);
        }
      } catch (err) {
        log(err, "CHECK LICENSE ERROR", false, true);
      }
    }
  };

  useEffect(() => {
    !!h && checkLicense();
  }, [!!h]);

  return isPaid;
};

export default useCheckLicense;

import React from "react";

const BigPicture = ({ classes, pictures }) => {
  const {
    JPG,
    JPG_2x,
    JPG_1200,
    JPG_1200_2x,
    JPG_992,
    JPG_992_2x,
    JPG_768,
    JPG_768_2x,
    JPG_Mob,
    JPG_Mob_2x,
    WEBP,
    WEBP_2x,
    WEBP_1200,
    WEBP_1200_2x,
    WEBP_992,
    WEBP_992_2x,
    WEBP_768,
    WEBP_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
  } = pictures;
  return (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1366px)"
        srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
      />
      <source media="(min-width: 1366px)" srcSet={`${JPG} 1x, ${JPG_2x} 2x`} />
      <source
        type="image/webp"
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${WEBP_1200} 1x, ${WEBP_1200_2x} 2x`}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${JPG_1200} 1x, ${JPG_1200_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${WEBP_992} 1x, ${WEBP_992_2x} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${JPG_992} 1x, ${JPG_992_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${WEBP_768} 1x, ${WEBP_768_2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${JPG_768} 1x, ${JPG_768_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${WEBP_Mob} 1x, ${JPG_Mob_2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${JPG_Mob} 1x, ${WEBP_Mob_2x} 2x`}
      />
      <img src={JPG} alt="" className={classes} loading="lazy" />
    </picture>
  );
};
export default BigPicture;

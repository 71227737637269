const RATING_NOW_CONTENT = {
  trustText: "Basierend auf Trustpilot-Bewertungen",
  excellentText: "Exzellent",
  isSecondTrust: true,
  isPictureBelow: true,
  posts: [
    {
      title: "AMZ Scout ist an erster Stelle…",
      description: "AMZ Scout ist das A und O, wenn es um den Verkauf auf Amazon geht. Es bietet alle Informationen, die Sie benötigen, um die perfekte Nische zu finden und Ihr zukünftiges Geschäft auszubauen.",
    },
    {
      title: "Ich mag diese Erweiterung sehr…",
      description: "Ich mag diese Erweiterung sehr. Ich benutze sie seit einem Monat und finde sie ergonomisch, einfach zu bedienen und zuverlässig. Das AMZScout-Kundenservice-Team ist auch sehr hilfreich und verständnisvoll. Ich vergebe 5 Sterne.",
    },
    {
      title: "Dieses Tool ist ein Muss für die Suche nach…",
      description: "Dieses Tool ist ein Muss, um Produkte zu finden, die man mit Gewinn auf Amazon weiterverkaufen kann. Sehr intuitiv, aber auch mit „Hinweisen\", die einem helfen, bis man sich daran gewöhnt hat.",
    },
  ],
}

export default RATING_NOW_CONTENT

import React from "react";

const Delta = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2074 8.29344C16.4836 9.0733 16.4836 10.9267 15.2074 11.7066L3.0429 19.1405C1.71019 19.9549 4.02331e-07 18.9957 4.02331e-07 17.4339V2.56611C4.02331e-07 1.00425 1.71019 0.0451185 3.0429 0.85955L15.2074 8.29344Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default Delta;

import React, { useContext, useEffect, useRef, useState } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { getTrueClasses } from "../helpers";
import { PAGES } from "../helpers/constants";
import RATING_NOW_CONTENT from "../components/de/main/RatingNow/index.content";
import Layout from "../layouts/LayoutDe";
import Seo from "../components/Seo";
import HomeIntro from "../components/de/main/HomeIntro";
import ChromeRating from "../components/common/ChromeRating";
import PartnerBrand from "../components/common/PartnerBrand";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import ProValue from "../components/de/main/ProValue";
import ProInstall from "../components/de/main/ProInstall";
import SuccessStories from "../components/de/main/SuccessStories";
import Guides from "../components/de/main/Guides";
import FormBlockContainer from "../containers/common/forms/FormBlockContainer";
import RatingNow from "../components/common/RatingNow";
import Idea from "../components/de/main/Idea";
import "../components/de/main/RatingNow/index.scss";
import "../components/de/main/Form/index.scss";
import "../components/de/main/index.scss";

const DeMain = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const pricingRef = useRef(null);
  const homeIntroRef = useRef(null);

  const formData = {
    buttonTextFirstState: "Probieren Sie AMZScout kostenlos aus!",
    buttonTextSecondState: "PROBIEREN SIE AMZSCOUT KOSTENLOS AUS!",
    language: "de",
    page: PAGES.MAIN_PAGE,
  };

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  return (
    <Layout
      customClass="PgDMain"
      isShowYoutubeModal={isShowYoutubeModal}
      tariffsElement={pricingRef}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      page={PAGES.MAIN_PAGE}
      isShowLanguageSwitcher
    >
      <Seo
        title="AMZScout | Amazon-Tools für die FBA-Produktrecherche"
        description="Erleichtern Sie die Amazon-Produktrecherche mit präzisen Verkaufstools: AMZScout-PRO-Chrome-Erweiterung, FBA-Rechner, Keyword-Suche und vielen anderen Tools. Probieren Sie es kostenlos aus und finden Sie die besten Produkte für den Verkauf."
        page="de"
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <HomeIntro homeIntroRef={homeIntroRef} />
      <ChromeRating langCode="de" />
      <PartnerBrand />
      <RatingNow customClass="PgDMain-RN" {...RATING_NOW_CONTENT} />
      <CustomizableContainer
        pricingRef={pricingRef}
        customClass="PgDMain-Pricing"
        language="de"
        subTitlePro
      />
      <ProValue />
      <FormBlockContainer
        title="E-Mail eintragen, um Ihre KOSTENLOSE TESTZEIT zu starten!"
        customClass={getTrueClasses(
          "PgDMain-form",
          "PgDMain-form_central",
          isAuth && "PgDMain-form_secondState PgDMain-form_secondState_central"
        )}
        {...formData}
      />
      <ProInstall />
      <SuccessStories />
      <Idea />
      <Guides />
      <FormBlockContainer
        title="Finden Sie jetzt profitable Produkte auf Amazon mit einer kostenlosen Testzeit!"
        customClass={getTrueClasses(
          "PgDMain-form",
          "PgDMain-form_bottom",
          isAuth && "PgDMain-form_secondState PgDMain-form_secondState_bottom"
        )}
        {...formData}
      />
    </Layout>
  );
};

export default DeMain;

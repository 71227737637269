import React from "react";
import IDEA_SCREENSHOT_CONTENT from "./index.content";
import SimpleScreenshot from "../../../../common/UI/SimpleScreenshot";
import "./index.scss";

const IdeaScreenshot = () => {
  const { firstScreenshot, secondScreenshot } = IDEA_SCREENSHOT_CONTENT;
  return (
    <div className="PgDMain-I-S">
      <SimpleScreenshot
        screenshot={firstScreenshot}
        classes="PgDMain-I-S__img PgDMain-I-S__img_one"
      />
      <SimpleScreenshot
        screenshot={secondScreenshot}
        classes="PgDMain-I-S__img PgDMain-I-S__img_two"
      />
    </div>
  );
};

export default IdeaScreenshot;

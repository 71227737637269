import PointIcon from "../../../../../assets/images/vector/components/common/group-copy.svg";

const POINTS_CONTENT = {
  listTexts: [
    "Profitable, konkurrenzarme Produkte finden",
    "Lernen Sie, wie Sie durch Eigenmarken, Arbitrage, Dropshipping und Großhandel verkaufen können",
    "Beliebte Produkt-Keywords und verifizierte Lieferanten entdecken",
  ],
  PointIcon,
}

export default POINTS_CONTENT

import React from "react";
import { getTrueClasses } from "../../../../../helpers";
import BigPicture from "./BigPicture";
import "./index.scss";

const Item = ({ text, href, picture, typePicture, title }) => {
  return (
    <a
      className={getTrueClasses(
        "PgDMain-G-I",
        typePicture === "big" && "PgDMain-G-I_big"
      )}
      href={href}
    >
      <BigPicture classes="PgDMain-G-I__img" pictures={picture} />
      <div className="PgDMain-G-I__title">{title}</div>
      <div className="PgDMain-G-I__text">{text}</div>
    </a>
  );
};

export default Item;

import React from "react";
import Link from "./Link";
import Item from "./Item";
import IDEA_GUIDES_LIST from "./index.content";
import "./index.scss";

const Guides = () => {
  return (
    <section className="PgDMain-G">
      <div className="container">
        <h2 className="PgDMain-G__title">
          Erfahren Sie, wie Sie auf Amazon verkaufen
        </h2>
        <div className="PgDMain-G__subtitle">
          Leitfäden und Tipps für neue Amazon-Verkäufer
          <Link />
        </div>
        <div className="PgDMain-G-list">
          {IDEA_GUIDES_LIST.map((arg, i) => (
            <Item key={i} {...arg} />
          ))}
        </div>
        <Link mobile />
      </div>
    </section>
  );
};

export default Guides;
